import styled from 'styled-components'
import { Form } from 'antd'
import { colors } from 'Common/constants'

export const FormItem = styled(Form.Item)`
  max-width: 800px;
  margin: auto auto 24px;
  & label {
    color: ${colors.grey4};
    font-weight: 500;
    text-transform: uppercase;
  }
  & .ant-form-item-children {
    & > *:last-child {
      margin-top: 8px;
    }
    & > *:first-child {
      margin-top: 0;
    }
  }
`
