import React from 'react'
import PropTypes from 'prop-types'
import { ErrorWrapper, ErrorLabel, ErrorValue } from './styled'

const ErrorRow = props => {
  const { label, value, description} = props
  return (
    <ErrorWrapper key={label}>
      <ErrorLabel>{label}</ErrorLabel>
        {
            value.split("\n").map((val, index) => {
                return <ErrorValue key={index}>{val}</ErrorValue>
            })
        }
        <ErrorValue>{description}</ErrorValue>
    </ErrorWrapper>
  )
}
ErrorRow.propTypes = {
  key: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}
export default ErrorRow
