import {
  GET_LAST_ELABORATION,
  UPLOAD,
  GET_REPORT,
} from '../action-types'

export const initialState = { lastElaboration: [], report: [] }
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPLOAD:
      return { ...state, uploadFeedback: payload }    
    case GET_REPORT:
      return { ...state, report: payload }
    case GET_LAST_ELABORATION:
        return { ...state, lastElaboration: payload }
    default:
      return state
  }
}
