import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Form, Upload, Icon } from 'antd'
import { FormItem } from './styled'

const { Dragger } = Upload

class UploadForm extends Component {
  onInputProject = e => {
    const { onChange } = this.props
    const value = e.target.value
    if (value.length > 3) {
      onChange({ projectName: value })
    }
  }
  renderUploadIcon() {
    const { isLoading, values } = this.props
    if (isLoading) return 'loading-3-quarters'
    if (values.xlsx) return 'check'
    return 'inbox'
  }
  renderUpload() {
    const { isLoading, onChange } = this.props
    return (
      <Dragger
        disabled={isLoading}
        accept=".xls,.xlsx"
        showUploadList={false}
        customRequest={e => onChange({ xlsx: e.file })}>
        <p className="ant-upload-drag-icon">
          <Icon type={this.renderUploadIcon()} spin={isLoading} />
        </p>
        <p className="ant-upload-text">{'Clicca o trascina il file'}</p>
        <p className="ant-upload-hint">
          {'Il file deve essere in formato .xls o .xlsx'}
        </p>
      </Dragger>
    )
  }
  
  render() {
    return (
      <Form>
        <FormItem label={'File'}>{this.renderUpload()}</FormItem>
      </Form>
    )
  }
}
UploadForm.propTypes = {
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  values: PropTypes.object,
}
export default Form.create({
  onValuesChange(props, changedValues, allValues) {
    props.onChange(changedValues)
  },
})(UploadForm)
