import styled from 'styled-components'
import { colors } from 'Common/constants'

export const FeedbackWrapper = styled.div`
  height: 40px;
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, 50%);
  align-items: center;
`
export const FeedbackLabel = styled.h2`
  justify-self: right;
  margin: 0;
`
export const FeedbackValue = styled.h3`
  justify-self: left;
  margin: 0;
  color: ${colors.grey4};
  font-weight: normal;
`
