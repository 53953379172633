import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  getReport,
  downloadReport, 
} from 'Redux/actions/app-actions'

import { PageTitle } from 'Common/styled'
import ReportTable from 'Components/ReportTable'

class Report extends Component {
  static propTypes = {
    downloadReport: PropTypes.func,
    getReport: PropTypes.func,
    isController: PropTypes.bool,
    //report: PropTypes.arrayOf(PropTypes.object),
  }
  state = {
    isLoading: false,
  }

  componentDidMount() {
    this.getReport({
      current: 1,
      pageSize: 10,
    })
  }

  getReport = (params) => {
    const { getReport } = this.props
    this.setState({ isLoading: true })
    getReport({
      page:params.current-1, 
      size: params.pageSize,
      sortField: params.field,
      sortOrder:params.order==='ascend'?'ASC':'DESC'
    }).then(() => this.setState({ isLoading: false }))
  }
  downloadReport = id => {
    const { downloadReport } = this.props
    this.setState({ isLoading: true })
    downloadReport(id).then(() => this.setState({ isLoading: false }))
  }
  handleTableChange = (newPagination, filters, sorter) => {
    this.getReport({
      ...sorter,
      ...newPagination,
      //...filters,
    });
  };

  render() {
    const { isLoading } = this.state
    const { report } = this.props
    const pagination = {current: report.pageable?.pageNumber +1 ||1,
      pageSize: report.pageable?.pageSize||0,
      total:report.totalElements}
    return (
      <>
        <PageTitle>{'Gestione Documenti'}</PageTitle>
       
        <ReportTable
          isLoading={isLoading}
          data={report.content}
          downloadReport={this.downloadReport}
          pagination= {pagination}
          handleTableChange = {(newPagination, filters, sorter)=>this.handleTableChange(newPagination, filters, sorter)}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  isController: state.user.isController,
  report: state.app.report,
})
const mapDispatchToProps = {
  getReport,
  downloadReport, 
}
export default connect(mapStateToProps, mapDispatchToProps)(Report)
