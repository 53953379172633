import API from './axiosConfig'

export default {
  user: {
    authenticate: token =>
      API.get('/excel/login', {
        headers: { Authorization: `Bearer ${token}` },
      }),
  },
  save: {
    upload: data =>
      API.post('/excel/upload', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    
  },
  excel:{
    rules: () => API.get('/excel/rules'),
    loadRules: ({uploadedFileId, ldap, forceLoad}) => API.put('/excel/load-rules', null, { params: { uploadedFileId, ldap, forceLoad }}),
    uploads: (params)=> API.get('/excel/uploads/pageable', {params}),
    download: uploadedFileId =>
      API.get('/excel/download', { params: { uploadedFileId }, responseType: 'blob' }),
  }
}
