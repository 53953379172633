import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import Auth from 'Containers/Auth'
import Home from 'Containers/Home'
import Report from 'Containers/Report'
import Page404 from 'Containers/404'
import Header from 'Components/Header'
import Footer from 'Components/Footer'
import { WhiteLayout, PaddedContent } from 'Common/styled'

class App extends Component {
  static propTypes = {
    user: PropTypes.object,
  }
  render() {
    const { user } = this.props
    return (
      <WhiteLayout>
        <Header user={user} />
        <Auth>
          <PaddedContent>
            <Switch>
              <Route component={Home} exact path={'/'} />
              <Route component={Report} path={'/report'} />
              <Route component={Page404} />
            </Switch>
          </PaddedContent>
        </Auth>
        <Footer />        
      </WhiteLayout>
    )
  }
}
const mapStateToProps = state => ({
  user: state.user,
})
export default connect(mapStateToProps)(App)
