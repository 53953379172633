import React from 'react'
import PropTypes from 'prop-types'
import { FeedbackWrapper, FeedbackLabel, FeedbackValue } from './styled'

const FeedbackRow = props => {
  const { label, value } = props
  return (
    <FeedbackWrapper>
      <FeedbackLabel>{label}</FeedbackLabel>
      <FeedbackValue>{value}</FeedbackValue>
    </FeedbackWrapper>
  )
}
FeedbackRow.propTypes = {
  key: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}
export default FeedbackRow
