import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { Link } from 'react-router-dom'

import Menu from 'Components/Menu'
import User from 'Components/User'
import { StyledHeader, HeaderBar, AppLogo } from './styled'

const Header = props => {
  const { user } = props
  return (
    <>
      <HeaderBar />
      <StyledHeader>
        <Link to={'/'}>
          <AppLogo src="/assets/img/logo.png" />
        </Link>
        <Menu />
        {!isEmpty(user) ? <User user={user} /> : null}
      </StyledHeader>
    </>
  )
}

Header.propTypes = {
  user: PropTypes.object,
}
export default Header
