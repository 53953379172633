import React from 'react'
import PropTypes from 'prop-types'
import { Avatar } from 'antd'

import { Username } from './styled'

import { getShortName } from 'Common/utils'

const User = props => {
  const { user } = props
  return (
    <div>
      <Avatar size={'small'} icon="user" />
      <Username>{getShortName(user.name, user.surname)}</Username>
    </div>
  )
}
User.propTypes = {
  user: PropTypes.object,
}
export default User
