import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import Loader from 'Components/Loader'
import Button from 'Components/Button'
import StatusIcon from 'Components/StatusIcon'

// function generateFilters(data, key) {
//   const uniqFilters = [...new Set(data.map(i => i[key]))]
//   return uniqFilters
//     .sort((a, b) => a - b)
//     .map(item => {
//       let text = item=== true? "Si" : item === false ? 'No' : item;
//       return { text: text, value: item }
//     })
// }
const ReportTable = props => {
  const { isLoading, data, downloadReport,pagination, handleTableChange  } = props
  // const filterProps = key => ({
  //   filters: generateFilters(data, key),
  //   filterMultiple: false,
  //   onFilter: (value, row) => row[key] === value,
  // })
  const columns = [
    {
      key: 'id',
      title: 'Id Documento',
      dataIndex: 'id',
      sorter: true      
    },
    {
      key: 'date',
      title: 'Data Caricamento',
      dataIndex: 'date',
      render: (val)=> new Date(val).toLocaleString(), 
      sorter: true,     
    },
    {
      key: 'filename',
      title: 'Nome File',
      dataIndex: 'filename',
      sorter: true
    },  
    {
      key: 'dimension',
      title: 'Dimensione',
      dataIndex: 'dimension',
      sorter: true
    },  
    {
      key: 'successLoaded',
      title: 'Validato',
      dataIndex: 'successLoaded',
      sorter: true,
      render: (val)=><StatusIcon isOk={val} status={val? "success" : "error"}/>, 
            
    },
    {
      key: 'download',
      title: 'Download',
      render: row => (
        <Button
          icon={'download'}
          //disabled={!row.status}
          onClick={() => downloadReport(row.id)}
        />
      ),
    },
  ]

  return (
    <Table
      size={'middle'}
      columns={columns}
      dataSource={data}
      rowKey={row => row.id}
      pagination={pagination}
      loading={{ spinning: isLoading, indicator: <Loader /> }}
      onChange={handleTableChange}
    />
  )
}
ReportTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  downloadReport: PropTypes.func,
  isLoading: PropTypes.bool,
}
export default ReportTable
