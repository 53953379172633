import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { omit } from 'ramda'
import { Card, Alert, message } from 'antd'
import { connect } from 'react-redux'

import {
  getLastElaboration,
  upload,
  commit,
} from 'Redux/actions/app-actions'

import UploadForm from 'Components/UploadForm'
import Button from 'Components/Button'
import LastElaborationTable from 'Components/LastElaborationTable'
import FeedbackRow from 'Components/FeedbackRow'
import ErrorRow from 'Components/ErrorRow'
import StatusIcon from 'Components/StatusIcon'
import { PageTitle, PageSubTitle } from 'Common/styled'

const initialState = {
  isLoading: false,
  hasFeedback: false,
  formValues: {},
}
class Home extends Component {
  static propTypes = {
    commit: PropTypes.func,
    getProjects: PropTypes.func,
    getLastElaboration: PropTypes.func,
    upload: PropTypes.func,

  }
  state = initialState

  componentDidMount() {
    this.getLastElaboration()
  }

  getLastElaboration = () => {
    const { getLastElaboration } = this.props
    this.setState({ isLoading: true })
    getLastElaboration().then(() => this.setState({ isLoading: false }))
  }
    
  handleFormChange = changedValues => {
    this.setState(prevState => ({
      formValues: { ...prevState.formValues, ...changedValues },
    }))
  }
  handleFormSubmit = () => {
    const { formValues } = this.state
    const { xlsx} = formValues
    const { upload, ldap } = this.props
    const formdata = new FormData()

    formdata.append('file', xlsx)
    formdata.append('ldap', ldap)
    
    this.setState({ isLoading: true })
    upload(formdata).then(res => {
      const newState = { isLoading: false }
      if (res){
        message.success('Caricamento avvenuto con successo', 5)
        this.resetPage();
        this.getLastElaboration();
      } else {
        newState.hasFeedback = true;
        this.setState(newState);
      }      
    })
  }
  handleSave = () => {
    // let newState = { hasFeedback: false }
    // this.setState(newState);
    const { feedback, commit, ldap } = this.props
    this.setState({ isLoading: true })
    commit(feedback.uploadedFileResponse.id, ldap, true).then(res => {
      const newState = { isLoading: false }
      this.setState(newState);
      if (!res.error){        
        message.success('Caricamento avvenuto con successo', 5)
        this.resetPage();
        this.getLastElaboration();
      } 
      else{
        newState.hasFeedback = true
        this.setState(newState);        
      }      
    })
  }
  resetPage = () => this.setState(initialState)

  renderFeedback() {
    const { isLoading } = this.state
    const { feedback } = this.props
    const message = feedback.message
    const {
      filename,
      date,
      errorsExcel =[],
      status,
      // isCompliant,
      // complianceError,
      successLoaded,
    } = feedback.uploadedFileResponse

    return (
      <>
        <Card>
          <FeedbackRow label={'Nome File'} value={filename} />
          <FeedbackRow label={'Data'} value={(new Date(date)).toLocaleString()} />
            <FeedbackRow
            label={'Validazione'}
            value={<StatusIcon isOk={successLoaded} status={status} />}
          />
        </Card>
        {successLoaded ? (
          <div style={{ justifySelf: 'center' }}>
            <Button
              ghost
              style={{ marginRight: 8 }}
              icon={'arrow-left'}
              onClick={this.resetPage}>
              {'Torna indietro'}
            </Button>
            
          </div>            
        ) : (
          <>
            <div style={{ justifySelf: 'center' }}>
              <Button
                ghost
                style={{ marginRight: 8 }}
                icon={'arrow-left'}
                onClick={this.resetPage}>
                {'Torna indietro'}
              </Button>
              {status==='warning' &&<Button
                loading={isLoading}
                icon={'upload'}
                onClick={this.handleSave}>
                {'Carica comunque'}
              </Button>}
            </div>

            <Alert
              message={message}
              description={this.renderErrorMessage (errorsExcel)}
              type={status}
             showIcon
            />
            
            
          </>
        )}          
      </>
    )
  }

  renderErrorMessage(errorsExcelDbs){
    let message = [];
    for (const error of errorsExcelDbs){
      if(error !== null){
        const rowError = <li key={error.row}><ErrorRow label={'Riga '+ error.row} value={error.typeError} description={error.description}/></li>
        message.push(rowError)
      }      
    }
    return message;
  }

  renderForm() {
    const { isLoading, formValues } = this.state
    const formKeys = ['xlsx']
    const isSubmitEnabled = formKeys.every(k => formValues.hasOwnProperty(k))
    const { lastElaboration} = this.props
    const {rules =[], date} = lastElaboration
    const elaborationDate= date? `del ${(new Date(date)).toLocaleString()}` : '';

    return (
      <>
        <UploadForm
          isLoading={isLoading}
          values={formValues}          
          onChange={this.handleFormChange}          
        />
        <Button
          loading={isLoading}
          disabled={!isSubmitEnabled}
          icon={'upload'}
          onClick={this.handleFormSubmit}
          style={{ justifySelf: 'center' }}>
          {'Carica e verifica'}
        </Button>
        <PageSubTitle>{'Ultimo caricamento ' + elaborationDate}</PageSubTitle>
        <LastElaborationTable
            isLoading={isLoading}
            data={rules}          
        />
      </>
    )
  }
  render() {
    const { hasFeedback } = this.state
    //const {feedback} = this.props
    
    return (
      <>
        <PageTitle>{'Caricamento file LastMiles - Carrier Identification'}</PageTitle>
        {hasFeedback ? this.renderFeedback() : this.renderForm()}

        
      </>
    )
  }
}

const mapStateToProps = state => ({
  feedback: state.app.uploadFeedback,  
  lastElaboration: state.app.lastElaboration,
  ldap: state.user.ldap
})
const mapDispatchToProps = {
  upload,
  commit,
  getLastElaboration,
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)
