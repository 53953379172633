import styled from 'styled-components'
import { colors } from 'Common/constants'

export const ErrorWrapper = styled.div`
  gap: 8px;
  align-items: left;
`
export const ErrorLabel = styled.h3`
  justify-self: right;
  margin: 0;
`
export const ErrorValue = styled.h4`
  justify-self: left;
  margin: 0;
  color: ${colors.grey4};
  font-weight: normal;
`
