import React from 'react'
import PropTypes from 'prop-types'
import { Avatar } from 'antd'

import { colors } from 'Common/constants'
//const { success, warning, error } = colors

const StatusIcon = props => {
  const { isOk, status } = props
  const backgroundColor = colors[status];
  return (
    <Avatar
      size={'small'}
      icon={isOk ? 'check' : 'close'}
      style={{
        background: backgroundColor,
      }}
    />
  )
}
StatusIcon.propTypes = {
  status: PropTypes.string,
  isOk: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
}
export default StatusIcon
