const env = process.env.REACT_APP_ENV
const redirectAfterLogin = encodeURIComponent(window.location.href)
const envConfig = {
  dev: {
    LOGIN_URL: `https://test-lmit-lastmilecarrierid-itlm-ping-vdhytb2yfa-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'http://localhost:8080/api/v1',
  },
  test: {
    LOGIN_URL: `https://test-lmit-lastmilecarrierid-itlm-ping-vdhytb2yfa-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://lmit-lastmilecarrierid-test-vdhytb2yfa-ew.a.run.app/api/v1',
  },
  prod: {
    LOGIN_URL: `https://prod-lmit-lastmilecarrierid-itlm-ping-vdhytb2yfa-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://lmit-lastmilecarrierid-prod-vdhytb2yfa-ew.a.run.app/api/v1',
  },
}

export default envConfig[env]
