import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import Loader from 'Components/Loader'

function generateFilters(data, key) {
  const uniqFilters = [...new Set(data.map(i => i[key]))]
  return uniqFilters
    .sort((a, b) => a - b)
    .map(item => {
      let text = item=== true? "Si" : item === false ? 'No' : item;
      return { text: text, value: item }
    })
}
const LastElaborationTable = props => {
  const { isLoading, data } = props
  const filterProps = key => ({
    filters: generateFilters(data, key),
    filterMultiple: false,
    onFilter: (value, row) => row[key] === value,
  })
  const columns = [
    {
      key: 'expeditionType',
      title: 'EXPEDITION TYPE',
      dataIndex: 'expeditionType',
      sorter: (a, b) => a.expeditionType.localeCompare(b.expeditionType),
      //...filterProps('expeditionType'),
    },
    {
      key: 'branchWms',
      title: 'BRANCH WMS',
      dataIndex: 'branchWms',
      sorter: (a, b) => a.branchWms.localeCompare(b.branchWms),
      ...filterProps('branchWms'),
    },
    {
      key: 'code49',
      title: 'CODE49',
      dataIndex: 'code49',
      sorter: (a, b) => a.code49.localeCompare(b.code49),
      ...filterProps('code49'),
    },  
    {
      key: 'code49Description',
      title: 'CODE49 DESCRIPTION',
      dataIndex: 'code49Description',
      sorter: (a, b) => a.code49Description.localeCompare(b.code49Description),
      ...filterProps('code49Description'),
    },  
    {
        key: 'codShipVia',
        title: 'COD SHIP VIA',
        dataIndex: 'codShipVia',
        sorter: (a, b) => a.codShipVia.localeCompare(b.codShipVia),
        ...filterProps('codShipVia'),
      },
      {
        key: 'fragile',
        title: 'FRAGILE',
        dataIndex: 'fragile',
        sorter: (a, b) => a.fragile - b.fragile,
        render: (val)=><div className="text_overlap">{val ? 'Si':'No'}</div>,    
        ...filterProps('fragile'),    
      },
      {
        key: 'express',
        title: 'EXPRESS',
        dataIndex: 'express',
        sorter: (a, b) => a.express - b.express,
        render: (val)=><div className="text_overlap">{val ? 'Si':'No'}</div>,    
        ...filterProps('express'),    
      },
      {
        key: 'minWeightOnWh',
        title: 'MIN WEIGHT ON WH',
        dataIndex: 'minWeightOnWh',
        sorter: (a, b) => a.minWeightOnWh - b.minWeightOnWh,        
      },
      {
        key: 'maxWeightOnWh',
        title: 'MAX WEIGHT ON WH',
        dataIndex: 'maxWeightOnWh',
        sorter: (a, b) => a.maxWeightOnWh - b.maxWeightOnWh,
      },
      {
        key: 'minPostalCode',
        title: 'MIN POSTAL CODE',
        dataIndex: 'minPostalCode',
        sorter: (a, b) => a.minPostalCode - b.minPostalCode,
      },
      {
        key: 'maxPostalCode',
        title: 'MAX POSTAL CODE',
        dataIndex: 'maxPostalCode',
        sorter: (a, b) => a.maxPostalCode - b.maxPostalCode,
      },
      {
        key: 'carrierCodeOnWms',
        title: 'CARRIER CODE ON WM',
        dataIndex: 'carrierCodeOnWms',
        sorter: (a, b) => a.carrierCodeOnWMS - b.carrierCodeOnWMS,
        ...filterProps('carrierCodeOnWms'),
      },
      {
        key: 'netmoverCode',
        title: 'NETMOVER CODE',
        dataIndex: 'netmoverCode',
        sorter: (a, b) => a.netmoverCode - b.netmoverCode,
        ...filterProps('netmoverCode'),
      },     
  ]

  return (
    <Table
      size={'middle'}
      columns={columns}
      dataSource={data}
      rowKey={row => row.id}
      loading={{ spinning: isLoading, indicator: <Loader /> }}
    />
  )
}
LastElaborationTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
}
export default LastElaborationTable
