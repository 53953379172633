export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'

export const UPLOAD = 'UPLOAD'
export const COMMIT = 'COMMIT'

export const GET_REPORT = 'GET_REPORT'
export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT'
export const DOWNLOAD_INITIATIVES_REPORT = 'DOWNLOAD_INITIATIVES_REPORT'
export const DOWNLOAD_PRODUCTS_REPORT = 'DOWNLOAD_PRODUCTS_REPORT'

export const GET_LAST_ELABORATION = 'GET_LAST_ELABORATION'
