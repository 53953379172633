import {
  GET_LAST_ELABORATION,
  UPLOAD,
  GET_REPORT,
  DOWNLOAD_REPORT,  
} from '../action-types'
import api from 'Common/api'
import { handleServerError, handleFileDownload } from 'Common/utils'


export const getLastElaboration = () => async dispatch => {
  try {
    const res = await api.excel.rules()
    return dispatch({ type: GET_LAST_ELABORATION, payload: {'rules': res.data.rules, 'date': res.data.date} })
  } catch (error) {
    return handleServerError(error)
  }
}

export const upload = formdata => async dispatch => {
  try {
    const res = await api.save.upload(formdata)
    const { status, data } = res
    dispatch({ type: UPLOAD, payload: data})
    if(status === 200){
      return data.uploadedFileResponse.status === 'ok'? true: false;
    }
    
  } catch (error) {
    handleServerError(error)
    return { status: (error.response && error.response.status) || 500 }
  }
}
export const commit =  (uploadedFileId, ldap, forceLoad) => async dispatch => {
  try {
    const res = await api.excel.loadRules({uploadedFileId, ldap, forceLoad})
    const { data } = res
    console.log(res);
    dispatch({ type: UPLOAD, payload: data })
    return  {'error': data.error};
  } catch (error) {
     handleServerError(error);
    return { status: (error.response && error.response.status) || 500 }  
  }
}

export const getReport = (params={}) => async dispatch => {
  try {
    const res = await api.excel.uploads(params)
    return dispatch({ type: GET_REPORT, payload: res.data})
  } catch (error) {
    return handleServerError(error)
  }
}
export const downloadReport = id => async dispatch => {
  try {
    const res = await api.excel.download(id)
    const { data, headers } = res
    const contentDisposition = headers['content-disposition'];
    const filename = contentDisposition? contentDisposition.split('filename=')[1] : 'file.xlsx';    
    handleFileDownload(data, filename, headers['content-type'])
    return dispatch({ type: DOWNLOAD_REPORT })
  } catch (error) {
    return handleServerError(error)
  }
}

